import './App.css';
import PictureLink from './components/PictureLink';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ProfilePicture from './components/ProfilePicture';

function App() {
  return (
    <div className="App">
      <ProfilePicture />
      <PictureLink boxBackgroundColor="#caffbf" IconName={<YouTubeIcon className='iconStyles' />} text="Youtube" link="https://www.youtube.com/@cairogarden" />
      <PictureLink boxBackgroundColor="#fcf6bd" IconName={<InstagramIcon className='iconStyles' />} text="Instagram" link="https://instagram.com/cairogarden" />
      <PictureLink boxBackgroundColor="#ff99c8" IconName={<FacebookIcon className='iconStyles' />} text="Facebook" link="https://facebook.com/cairogardener" />
      <PictureLink boxBackgroundColor="#ffd6a5" IconName={<CameraswitchIcon className='iconStyles' />} text="TikTok" link="https://www.tiktok.com/@cairogarden" />
    </div>
  );
}


export default App;
