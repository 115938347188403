import React from 'react'
import '../App.css';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function PictureLink(props) {

    return (
        <div>
            <div >
                <Container maxWidth="xlg" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: 2
                }}>

                    <Box className='boxBackground'
                        sx={{
                            width: '85%',
                            height: '4rem',
                            backgroundColor: props.boxBackgroundColor,
                            borderRadius: 6,
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: 2,
                            boxShadow: 3,
                            mx: 'auto',
                            alignItems: 'center',

                        }}
                    >
                        <Avatar
                            alt="logo"
                            sx={{
                                width: 60, 
                                height: 60,
                                bgcolor: '#33a200',
                            }}
                        >{props.IconName}
                        </Avatar>
                        <Button variant="contained"
                            sx={{
                                width: '8em',
                                height: '3em',
                                fontSize: '1.1em',
                                borderRadius: 5,
                                backgroundColor: '#33a200',
                                fontWeight: 'bolder',
                                fontFamily: 'sans-serif',
                                fontVariant: 'unicase'
                            }}
                            href={props.link}>
                            {props.text}

                        </Button>

                    </Box>

                </Container>
            </div>

        </div>
    )
}
