import React from 'react'
import Avatar from '@mui/material/Avatar';

export default function ProfilePicture() {
    return (
        <div style={{
            display: 'flex', 
            justifyContent: 'center',
            alignContent: 'center',
            margin: 5

            }}>
            <Avatar
                alt="cairo garden"
                src="/logo.jpeg"
                sx={{ 
                    width: '6em', 
                    height: '6em',
                    marginBottom: 3,
                    marginTop: 6,
                    border: 6,
                    borderColor: '#33a200',

                 }}
            />
        </div>
    )
}
